export const isBlockChainValid = (blockchain) => {
  if (
    blockchain === "" ||
    blockchain === "null" ||
    blockchain === "undefined" ||
    blockchain === null ||
    blockchain === undefined
  )
    return false;
  else return true;
};

export const isContractAddressValid = (address, bc) => {
  if (address && bc === "solana") return true;
  if (address && bc === "tron") return true;
  if (
    address === "" ||
    address === "null" ||
    address === "undefined" ||
    address === null ||
    address === undefined ||
    address?.slice(0, 2) !== "0x"
  )
    return false;
  else return true;
};

export const isSocialLinkValid = (link) => {
  if (
    link === "" ||
    link === "null" ||
    link === "undefined" ||
    link === null ||
    link === undefined
  )
    return false;
  else return true;
};

export const isAmountValid = (amount) => {
  if (!isNaN(amount) && amount >= 0) return true;
  else return false;
};
