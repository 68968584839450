import styled from "styled-components";
import { Row } from "../containers/flexbox.styled";

export const StyledNavbar = styled(Row)`
  width: 100%;
  background-color: ${({ theme, noBg }) =>
    noBg ? "transparent" : theme.colors.SECONDARY};
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 2.2rem;
  gap: 1rem;

  @media (max-width: 70rem) {
    padding: 0.6rem 1rem 0.6rem 0.6rem;
  }
`;
