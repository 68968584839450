import styled from "styled-components";
import { ShortBox } from "../containers/boxes.styled";
import { borderWidths } from "../sizes";

export const StyledCompare = styled(ShortBox)`
  border: ${borderWidths.SMALL} solid ${({ theme }) => theme.colors.LEVEL};
  padding: 1rem;

  height: 25rem;
  .holder-loader {
    height: 15.5rem !important;
    justify-content: center;
  }

  @media (max-width: 70rem) {
    height: auto;
    .holder-loader {
      height: 12.05rem !important;
    }
  }

  @media (max-width: 40rem) {
    padding: 0.75rem;
    & > *:first-child {
      gap: 0.75rem;
    }
  }
`;
