import styled from "styled-components";
import { VerticalSeperator } from "../containers/boxes.styled";
import { Column, FullRow, Row } from "../containers/flexbox.styled";
import { borderRads, borderWidths, textSizes } from "../sizes";
import { Header, Paragraph, SmallText } from "../texts.styled";
import { theme } from "../theme.styled";
import { CoinInfoSection, MostComparedBadge } from "./coinInfo.styled";
import { DedicatedCoinContext } from "../../context";
import React, { useContext, useEffect, useState } from "react";
import { getMostComparedAll } from "../stream";
import { useQuery } from "@tanstack/react-query";
import { Icon } from "../icon.styled";
import { IoMdFlame } from "react-icons/io";
import { getSuffix } from "./coinInfo";
import {
  formatNumber,
  getSmallNumber,
  graphFormatter,
} from "../../util/numberFormatter";
import { PriceChange, PriceChangeMobile } from "./priceChange.styled";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import CopyButton from "../misc/copyButton";
import {
  isBlockChainValid,
  isContractAddressValid,
  isSocialLinkValid,
} from "../../validators";
import { FaTelegramPlane } from "react-icons/fa";
import { TransparentButton } from "../buttons/buttons.styled";
import { BiWorld } from "react-icons/bi";
import DedicatedCoinShareButton from "./dedicatedCoinShareButton";
import { RouteLink } from "../../routing/routeLink";
import { Tags } from "../../pages/dedicatedCoinNewDesktop";

export const CoinInfo = ({ data, holderCount, oneDayChange }) => {
  return (
    <FullRow gap="1rem" align="start">
      <CoreInfo holderCount={holderCount} oneDayChange={oneDayChange} />
      <TopRight coinData={data} holderCount={holderCount} />
    </FullRow>
  );
};

export const CoreInfo = ({ holderCount, oneDayChange }) => {
  const coinData = useContext(DedicatedCoinContext);

  const [mcIndex, setMcIndex] = useState(null);

  const { data, status } = useQuery(
    ["mc-rank-2", coinData.id],
    () => getMostComparedAll(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setMcIndex(data.findIndex((item) => item.id === coinData.id) + 1);
      },
    }
  );

  return (
    <StyledCoreInfo
      shouldBorder={true}
      volumeOrHolder={coinData.volume24H || holderCount}
    >
      <FullRow justify="space-between">
        <Row gap="1rem">
          <img
            style={{ width: "2rem" }}
            src={coinData.image}
            alt={coinData.coinName}
          />
          <Row gap="1rem">
            <Header weight={textSizes.fontWeights.SEMI_BOLD}>
              {coinData.symbol.toUpperCase()}
            </Header>
            <VerticalSeperator />
            <SmallText color={theme.colors.LIGHT_TEXT}>
              {coinData.coinName}
            </SmallText>
          </Row>
        </Row>
        {status === "success" &&
          mcIndex !== null &&
          mcIndex < 20 &&
          mcIndex !== 0 && (
            <MostComparedBadge>
              <Icon
                style={{ overflowX: "visible" }}
                color="#ffb800"
                size="1.2rem"
              >
                <IoMdFlame color="#ffb800" />
              </Icon>
              <SmallText>{getSuffix(mcIndex)}</SmallText>
            </MostComparedBadge>
          )}
      </FullRow>
      <div></div>
      <FullRow align="start" gap="0.75rem">
        <Column
          align="center"
          gap="0.3rem"
          style={{
            width: "100%",
            padding: "0.5rem",
            backgroundColor: theme.colors.LEVEL,
            borderRadius: borderRads.SMALL,
          }}
        >
          <SmallText color={theme.colors.LIGHT_TEXT}>Price</SmallText>
          <Row gap="0.5rem" style={{ width: "fit-content" }}>
            <Header weight={textSizes.fontWeights.SEMI_BOLD} size="1.1rem">
              {coinData.price < 0.01 ? (
                <span>
                  $0.0
                  <SmallText size={"0.7rem"}>
                    {getSmallNumber(coinData.price).zeroCount}
                  </SmallText>
                  {getSmallNumber(coinData.price).nonZeroDigits}
                </span>
              ) : (
                <span>${formatNumber(coinData.price)}</span>
              )}
            </Header>
            {oneDayChange && (
              <PriceChangeMobile profit={oneDayChange >= 0}>
                {oneDayChange >= 0 ? (
                  <Icon>
                    <BsCaretUpFill />
                  </Icon>
                ) : (
                  <Icon>
                    <BsCaretDownFill />
                  </Icon>
                )}
                {formatNumber(oneDayChange, false, true)}%
              </PriceChangeMobile>
            )}
          </Row>
        </Column>
        <Column
          align="center"
          gap="0.3rem"
          style={{
            width: "100%",
            padding: "0.5rem",
            backgroundColor: theme.colors.LEVEL,
            borderRadius: borderRads.SMALL,
          }}
        >
          <SmallText
            style={{ whiteSpace: "nowrap" }}
            color={theme.colors.LIGHT_TEXT}
          >
            Market Cap
          </SmallText>
          <Header weight={textSizes.fontWeights.REGULAR} size="1.1rem">
            {coinData.marketcap < 0.01 ? (
              <span>
                $0.0
                <SmallText size={"0.7rem"}>
                  {getSmallNumber(coinData.marketcap).zeroCount}
                </SmallText>
                {getSmallNumber(coinData.marketcap).nonZeroDigits}
              </span>
            ) : (
              <span>
                ${graphFormatter(coinData.marketcap, false, false, true)}
              </span>
            )}
          </Header>
        </Column>
      </FullRow>
      <FullRow align="start" gap="0.75rem">
        {coinData.volume24H && (
          <Column
            align="center"
            gap="0.3rem"
            style={{
              width: "100%",
              padding: "0.5rem",
              backgroundColor: theme.colors.LEVEL,
              borderRadius: borderRads.SMALL,
            }}
          >
            <SmallText color={theme.colors.LIGHT_TEXT}>Volume</SmallText>
            <Header weight={textSizes.fontWeights.REGULAR} size="1.1rem">
              {coinData.volume24H < 0.01 ? (
                <span>
                  $0.0
                  <SmallText size={"0.7rem"}>
                    {getSmallNumber(coinData.volume24H).zeroCount}
                  </SmallText>
                  {getSmallNumber(coinData.volume24H).nonZeroDigits}
                </span>
              ) : (
                <span>
                  ${graphFormatter(coinData.volume24H, false, false, true)}
                </span>
              )}
            </Header>
          </Column>
        )}
        {holderCount && (
          <Column
            align="center"
            gap="0.3rem"
            style={{
              width: "100%",
              padding: "0.5rem",
              backgroundColor: theme.colors.LEVEL,
              borderRadius: borderRads.SMALL,
            }}
          >
            <SmallText color={theme.colors.LIGHT_TEXT}>Holders</SmallText>
            <Header weight={textSizes.fontWeights.REGULAR} size="1.1rem">
              {formatNumber(holderCount)}
            </Header>
          </Column>
        )}
      </FullRow>
    </StyledCoreInfo>
  );
};

const TopRight = ({ coinData, holderCount }) => {
  return (
    <StyledTopRight volumeOrHolder={coinData.volume24H || holderCount}>
      <SecondaryInfo coinData={coinData} />
      <Tags coin={coinData} />
      <FullRow align="center" gap="1rem">
        <StyledCoreInfo style={{ width: "100%", height: "100%", gap: "1rem" }}>
          {/* <SmallText color={theme.colors.LIGHT_TEXT}>
            See {coinData.symbol.toUpperCase()} on
          </SmallText> */}
          <FullRow align="center" gap="1rem">
            <RouteLink to={`/dreamfolio`}>
              <Column>
                <SmallText color={theme.colors.LIGHT_TEXT} size="0.8rem">
                  Do you hold {coinData.symbol.toUpperCase()}? Compare your
                  wallet with
                </SmallText>
                <img
                  style={{ height: "1rem" }}
                  src="/logos/dreamfolio-logo.png"
                  alt="dreamfolio"
                />
              </Column>
            </RouteLink>
            <RouteLink to={`/halving/${coinData.id}`}>
              <Column>
                <SmallText color={theme.colors.LIGHT_TEXT} size="0.8rem">
                  See {coinData.symbol.toUpperCase()}'s halving performance on
                </SmallText>
                <img
                  style={{ height: "0.67rem", marginBottom: "0.1rem" }}
                  src="/logos/halving-logo.png"
                  alt="halving"
                />
              </Column>
            </RouteLink>
          </FullRow>
        </StyledCoreInfo>
      </FullRow>
    </StyledTopRight>
  );
};

const SecondaryInfo = ({ coinData }) => {
  let shouldShowChain =
    isContractAddressValid(coinData.contractAddress, coinData.blockChain) &&
    isBlockChainValid(coinData.blockChain) &&
    (coinData.blockChain === "bsc" ||
      coinData.blockChain === "eth" ||
      coinData.blockChain === "base" ||
      coinData.blockChain === "solana" ||
      coinData.blockChain === "tron" ||
      coinData.blockChain === "pulsechain");

  let chainLink = "";
  let chainIconPath = "";

  if (shouldShowChain) {
    setupChain();
  }

  function setupChain() {
    if (coinData.blockChain === "bsc") {
      chainLink = "https://bscscan.com/token/";
      chainIconPath =
        "https://coin-images.coingecko.com/coins/images/825/small/bnb-icon2_2x.png?1696501970";
    } else if (coinData.blockChain === "eth") {
      chainLink = "https://etherscan.io/token/";
      chainIconPath =
        "https://coin-images.coingecko.com/coins/images/279/small/ethereum.png?1696501628";
    } else if (coinData.blockChain === "base") {
      chainLink = "https://basescan.org/token/";
      chainIconPath = "/logos/basescan-logo.png";
    } else if (coinData.blockChain === "solana") {
      chainLink = "https://solscan.io/token/";
      chainIconPath =
        "https://coin-images.coingecko.com/coins/images/4128/small/solana.png?1718769756";
    } else if (coinData.blockChain === "pulsechain") {
      chainLink =
        "https://scan.mypinata.cloud/ipfs/bafybeidn64pd2u525lmoipjl4nh3ooa2imd7huionjsdepdsphl5slfowy/#/token/";
      chainIconPath = "/logos/pulsechain.webp";
    } else if (coinData.blockChain === "tron") {
      chainLink = "https://tronscan.org/#/token20/";
      chainIconPath =
        "https://pbs.twimg.com/profile_images/1761904730668675072/v98T7vRL_400x400.jpg";
    }
    chainLink += coinData.contractAddress;
  }
  return (
    <Row gap="1.2rem" width="100%" justify="end">
      {coinData.contractAddress && coinData.contractAddress !== "undefined" && (
        <>
          <CoinInfoSection
            style={{ backgroundColor: "transparent", padding: "0" }}
          >
            <SmallText
              style={{ overflowX: "auto" }}
              color={({ theme }) => theme.colors.LIGHT_TEXT}
            >
              {coinData.contractAddress?.slice(0, 3)}...
              {coinData.contractAddress?.slice(-3)}
            </SmallText>
            <CopyButton text={coinData.contractAddress} />
          </CoinInfoSection>
          <VerticalSeperator
            width="0.07rem"
            color={"rgba(255,255,255,0.4)"}
            height="1.3rem"
          />
        </>
      )}

      {shouldShowChain && (
        <>
          <Row align="center" gap="0.5rem" width="fit-content !important">
            <a
              aria-label="Go to chain."
              target={"_blank"}
              href={chainLink}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ width: "1.1rem" }}
                alt="chain"
                src={chainIconPath}
              />
            </a>
            <a aria-label="Go to chain." target={"_blank"} href={chainLink}>
              <SmallText color={theme.colors.LIGHT_TEXT}>
                {coinData.blockChain === "base"
                  ? "BASE"
                  : coinData.blockChain.toUpperCase()}
              </SmallText>
            </a>
          </Row>
          <VerticalSeperator
            width="0.07rem"
            color={"rgba(255,255,255,0.4)"}
            height="1.3rem"
          />
        </>
      )}

      {isSocialLinkValid(coinData.web) && (
        <a
          aria-label="Go to website."
          href={
            coinData.web.slice(0, 8) === "https://"
              ? coinData.web
              : `https://${coinData.web}`
          }
          target={"_blank"}
        >
          <TransparentButton>
            <Icon color={({ theme }) => theme.colors.LIGHT_TEXT}>
              <BiWorld />
            </Icon>
          </TransparentButton>
        </a>
      )}

      {isSocialLinkValid(coinData.twitter) && (
        <a
          aria-label="Go to X profile."
          href={
            coinData.source === "ds"
              ? coinData.twitter
              : `https://x.com/${coinData.twitter.toString()}`
          }
          target={"_blank"}
        >
          <TransparentButton>
            <Icon color={({ theme }) => theme.colors.LIGHT_TEXT}>
              <svg
                version="1.1"
                id="svg5"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 1668.56 1221.19"
                xmlSpace="preserve"
              >
                <g id="layer1" transform="translate(52.390088,-25.058597)">
                  <path
                    id="path1009"
                    d="M283.94,167.31l386.39,516.64L281.5,1104h87.51l340.42-367.76L984.48,1104h297.8L874.15,558.3l361.92-390.99
                    h-87.51l-313.51,338.7l-253.31-338.7H283.94z M412.63,231.77h136.81l604.13,807.76h-136.81L412.63,231.77z"
                  />
                </g>
              </svg>
            </Icon>
          </TransparentButton>
        </a>
      )}

      {isSocialLinkValid(coinData.telegram) && (
        <a
          aria-label="Go to Telegram."
          href={`https://t.me/${coinData.telegram}`}
          target={"_blank"}
        >
          <TransparentButton>
            <Icon color={({ theme }) => theme.colors.LIGHT_TEXT}>
              <FaTelegramPlane />
            </Icon>
          </TransparentButton>
        </a>
      )}

      {(isSocialLinkValid(coinData.twitter) ||
        isSocialLinkValid(coinData.telegram) ||
        isSocialLinkValid(coinData.web)) && (
        <VerticalSeperator
          width="0.07rem"
          color={"rgba(255,255,255,0.4)"}
          height="1.3rem"
        />
      )}

      <DedicatedCoinShareButton size="1.2rem" coinName={coinData.coinName} />
    </Row>
  );
};

const StyledCoreInfo = styled(Column)`
  padding: 0.75rem;
  background-color: ${({ theme, shouldBorder }) =>
    shouldBorder ? theme.colors.LEVEL : theme.colors.LEVEL};
  border-radius: ${borderRads.SMALL};
  width: 50%;
  gap: 0.75rem;

  height: ${({ volumeOrHolder }) => (volumeOrHolder ? "auto" : "10rem")};

  border: ${({ borderColor, shouldBorder }) =>
    shouldBorder && `0.1rem solid rgba(255,255,255,0.1)`};

  //add drop shadow
  /* box-shadow: ${({ borderColor, shouldBorder }) =>
    shouldBorder && `0px 0px 6px ${borderColor}`}; */

  ${RouteLink} {
    width: 50%;
    background-color: ${({ theme }) => theme.colors.LEVEL};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${borderRads.SMALL};
    height: 6rem;

    & > * {
      padding: 1rem 0.5rem;
      height: 100%;
      justify-content: space-between;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.LEVEL2};
    }
  }
`;

const StyledTopRight = styled(Column)`
  gap: 0.5rem;
  width: 50%;
  justify-content: space-between;
  height: ${({ volumeOrHolder }) => (volumeOrHolder ? "13.3rem" : "auto")};

  svg {
    height: 1.2rem;
    fill: ${({ theme }) => theme.colors.LIGHT_TEXT};
  }

  ${Icon} {
    font-size: 1.2rem;
  }
`;

function hexToRgb(hex) {
  if (!hex) return;

  var bigint = parseInt(hex.replace("#", ""), 16);

  var r = (bigint >> 16) & 255;
  var g = (bigint >> 8) & 255;
  var b = bigint & 255;

  return `rgba(${r},${g},${b}, 0.4)`;
}
