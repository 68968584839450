import { Container } from "../components/containers/boxes.styled";
import MostCompared from "../components/misc/mostCompared/mostCompared";
import { useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getCompareCoins } from "../components/stream";
import { AllCoinsContext, CoinsContext } from "../context";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { formatNumber } from "chart.js/helpers";
import { useContext } from "react";
import { Header, Paragraph } from "../components/texts.styled";
import { Column } from "../components/containers/flexbox.styled";
import { RouteLink } from "../routing/routeLink";
import React, { Suspense } from "react";
import Loader from "../components/misc/loader";

const CompareTopLazy = React.lazy(() =>
  import("../components/comparePage/compareTop")
);

const CompareGraphLazy = React.lazy(() =>
  import("../components/comparePage/compareGraph")
);

export function Compare() {
  let { coin1, coin2 } = useParams();

  const allItems = useContext(AllCoinsContext);

  let [searchParams, setSearchParams] = useSearchParams();
  const [amountInput, setAmount] = useState(null);
  const [amountType, setAmountType] = useState(null);

  const [coin1data, setCoin1data] = useState(null);
  const [coin2data, setCoin2data] = useState(null);

  const [noCoins, setNoCoins] = useState(false);

  const {
    data,
    status,
    refetch: fetch,
  } = useQuery(["compare"], () => getCompareCoins(coin1, coin2, true), {
    enabled: false,
    staleTime: Infinity,
    onSuccess: (data) => {
      setCoin1data(data.coin1data);
      if (searchParams.get("amount") !== null) {
        setAmount(searchParams.get("amount"));
        setAmountType("amount");
      } else if (searchParams.get("$") !== null) {
        setAmount(searchParams.get("$"));
        setAmountType("$");
      }
      setCoin2data(data.coin2data);
    },
  });

  useEffect(() => {
    if (
      coin1 === undefined ||
      coin2 === undefined ||
      coin1 === null ||
      coin2 === null
    ) {
      window.location.replace("/compare/ethereum/bitcoin");
    } else if (
      !allItems.map((item) => item.id).includes(coin1) ||
      !allItems.map((item) => item.id).includes(coin2)
    ) {
      setNoCoins(true);
    } else {
      fetch();
    }
  }, []);

  return (
    <Container>
      {coin1data !== null && coin2data !== null && (
        <Helmet>
          <title>
            {coin1data.coinName +
              " Price With Market Cap Of " +
              coin2data.coinName +
              " (" + coin1data.symbol.toUpperCase() + " vs " + coin2data.symbol.toUpperCase() + ")"}
          </title>
          {/* <meta name="coinzilla" content="7e7181bdad599d80e8d5b6d0c4799b4c" />
          <script src="https://coinzillatag.com/lib/sticky.js"></script>
          <script>
            {`
                window.coinzilla_sticky = window.coinzilla_sticky || [];
                    function czilla() {coinzilla_sticky.push(arguments);}
                    czilla('36662f1037eea083937');
                `}
          </script> */}
          <meta
            name="description"
            content={`Price of ${
              coin1data.coinName
            }(${coin1data.symbol.toUpperCase()}) with the market cap of ${
              coin2data.coinName
            }(${coin2data.symbol.toUpperCase()}) is $${formatNumber(
              coin1data.price * (coin2data.marketcap / coin1data.marketcap)
            )}(${formatNumber(
              (coin1data.price * (coin2data.marketcap / coin1data.marketcap)) /
                coin1data.price
            )}x) `}
          />
        </Helmet>
      )}

      <MostCompared />

      {noCoins && (
        <Column
          style={{ margin: "3rem auto", textAlign: "center", gap: "2rem" }}
        >
          <Header>
            One of the coins could not be found. Please select them from
            dropdowns.
          </Header>
          <RouteLink to={"/"}>
            <Paragraph>Go to Home Page</Paragraph>
          </RouteLink>
        </Column>
      )}

      {status === "success" && coin1data !== null && coin2data !== null && (
        <CoinsContext.Provider
          value={{ coin1data, coin2data, amountInput, amountType }}
        >
          <Suspense fallback={<Loader />}>
            <CompareTopLazy />
          </Suspense>
        </CoinsContext.Provider>
      )}
    </Container>
  );
}
