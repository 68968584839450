import styled from "styled-components";
import { EmptyBox } from "../containers/boxes.styled";
import { Column, Row } from "../containers/flexbox.styled";
import { paddings } from "../paddings.styled";
import { borderRads, borderWidths, textSizes } from "../sizes";
import { StyledDropdownMenu } from "./dropdownMenu.styled";
import { Paragraph, SmallText } from "../texts.styled";
import { theme } from "../theme.styled";
import { StyledDropdownItem } from "./dropdownItem.styled";

export const StyledDropdown = styled(EmptyBox)`
  position: relative;
  padding: ${paddings.MID};
  border-radius: ${({ ison }) =>
    ison
      ? `${borderRads.SMALL} ${borderRads.SMALL} 0 0`
      : `${borderRads.SMALL}`};
  outline: ${({ ison, theme }) =>
    ison && `${borderWidths.SMALL} solid ${theme.colors.LEVEL}`};
  background-color: ${({ theme }) => theme.colors.LEVEL};
  img {
    width: 2rem;
  }
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.LEVEL2};
  }
  @media (max-width: 40rem) {
    padding: ${paddings.LOW};

    .dropdown-coin-row {
      img {
        width: 1.75rem !important;
      }
      gap: 0.6rem;
      ${Column} {
        gap: 0.6rem;
        ${SmallText} {
          color: ${({ theme }) => theme.colors.LIGHT_TEXT};
        }
        ${Paragraph} {
          font-size: ${textSizes.fontSizes.MEDIUM};
        }
      }
    }
  }

  ${Column} {
    @media (max-width: 40rem) {
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }
  }
`;

export const StyledSmallDropdown = styled(EmptyBox)`
  position: relative;
  img {
    width: 1.5rem;
  }
  &:hover {
    cursor: pointer;
  }
  ${Row} {
    gap: 0.7rem;
  }
`;

export const StyledAquariumDropdown = styled(StyledDropdown)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  background-color: rgba(255, 255, 255, 0.1);
  min-width: 20rem;

  ${StyledDropdownMenu} {
    background-color: rgba(0, 0, 0, 0) !important;
  }

  @media (max-width: 40rem) {
    width: 100%;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

export const StyledDreamfolioDropdown = styled(StyledDropdown)`
  display: flex;

  flex-direction: row;
  align-items: center;

  width: ${({ isFull }) => (isFull ? "100%" : "fit-content")};
  background-color: rgba(255, 255, 255, 0.02);
  //background-color: #17072b;
  border: ${borderWidths.SMALL} solid rgba(255, 255, 255, 0.1);

  height: 3.2rem;

  &:hover {
    background-color: rgba(255, 255, 255, 0.03);
    //background-color: #17072b;
  }

  img {
    width: 1.5rem;
  }

  ${StyledDropdownMenu} {
    background-color: rgba(0, 0, 0, 0) !important;
  }

  ${StyledDropdownItem} {
    &:hover {
      background-color: black !important;
    }
  }

  @media (max-width: 40rem) {
    width: 100%;
  }
`;
