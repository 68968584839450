import React, { useContext, useEffect, useState } from "react";
import { TrendsContext } from "../../../context";
import { RouteLink } from "../../../routing/routeLink";
import { EmptyBox, VerticalSeperator } from "../../containers/boxes.styled";
import { textSizes } from "../../sizes";
import { Paragraph, SmallText } from "../../texts.styled";
import {
  MostComparedAd,
  MostComparedCoin,
  MostComparedCoins,
  MostComparedContent,
  MostComparedHeader,
  StyledMostCompared,
} from "./mostCompared.styled";
import { Row } from "../../containers/flexbox.styled";
import { Icon } from "../../icon.styled";
import { FaFire } from "react-icons/fa";
import { HideOnMobileWrapper } from "../../hideOnMobile";

export default function MostCompared() {
  const { mcData, trendingData } = useContext(TrendsContext);

  const [nameRemoved, setNameRemoved] = useState(false);

  //   useEffect(() => {
  //     setTimeout(() => {
  //       setNameRemoved(true);
  //     }, 3000);
  //   }, []);

  return (
    <StyledMostCompared>
      <MostComparedHeader>
        <RouteLink to={"/most-compared"}>
          <Row gap={"0.5rem"}>
            <Icon>
              <FaFire />
            </Icon>
            <HideOnMobileWrapper>
              <Paragraph $color={({ theme }) => theme.colors.LIGHT_TEXT}>
                Most Compared
              </Paragraph>
            </HideOnMobileWrapper>
          </Row>
        </RouteLink>
      </MostComparedHeader>

      <MostComparedContent>
        <MostComparedCoins>
          {mcData.map((item, index) => (
            <React.Fragment key={index}>
              <RouteLink to={`/coin/${item.id}`}>
                <MostComparedCoin key={index}>
                  <Paragraph size={textSizes.fontSizes.MEDIUM}>
                    {index + 1}
                  </Paragraph>
                  <SmallText></SmallText>
                  <img alt="logo" src={item.image} />
                  <Paragraph size={textSizes.fontSizes.MEDIUM} weight={700}>
                    {item.symbol?.toUpperCase()}
                  </Paragraph>
                  <SmallText $color={({ theme }) => theme.colors.LIGHT_TEXT}>
                    {item.coinName}
                  </SmallText>
                </MostComparedCoin>
              </RouteLink>
              {index !== mcData.length - 1 && <VerticalSeperator />}
            </React.Fragment>
          ))}

          <EmptyBox />
          <EmptyBox />
          <EmptyBox />
          {mcData.map((item, index) => (
            <React.Fragment key={index}>
              <RouteLink to={`/coin/${item.id}`}>
                <MostComparedCoin key={index}>
                  <Paragraph size={textSizes.fontSizes.MEDIUM}>
                    {index + 1}
                  </Paragraph>
                  <SmallText></SmallText>
                  <img alt="logo" src={item.image} />
                  <Paragraph size={textSizes.fontSizes.MEDIUM} weight={700}>
                    {item.symbol.toUpperCase()}
                  </Paragraph>
                  <SmallText $color={({ theme }) => theme.colors.LIGHT_TEXT}>
                    {item.coinName}
                  </SmallText>
                </MostComparedCoin>
              </RouteLink>
              {index !== mcData.length - 1 && <VerticalSeperator />}
            </React.Fragment>
          ))}

          <EmptyBox />
          <EmptyBox />
          <EmptyBox />
          {mcData.map((item, index) => (
            <React.Fragment key={index}>
              <RouteLink to={`/coin/${item.id}`}>
                <MostComparedCoin key={index}>
                  <Paragraph size={textSizes.fontSizes.MEDIUM}>
                    {index + 1}
                  </Paragraph>
                  <SmallText></SmallText>
                  <img alt="logo" src={item.image} />
                  <Paragraph size={textSizes.fontSizes.MEDIUM} weight={700}>
                    {item.symbol.toUpperCase()}
                  </Paragraph>
                  <SmallText $color={({ theme }) => theme.colors.LIGHT_TEXT}>
                    {item.coinName}
                  </SmallText>
                </MostComparedCoin>
              </RouteLink>
              {index !== mcData.length - 1 && <VerticalSeperator />}
            </React.Fragment>
          ))}

          <EmptyBox />
          <EmptyBox />
          <EmptyBox />
          {mcData.map((item, index) => (
            <React.Fragment key={index}>
              <RouteLink to={`/coin/${item.id}`}>
                <MostComparedCoin key={index}>
                  <Paragraph size={textSizes.fontSizes.MEDIUM}>
                    {index + 1}
                  </Paragraph>
                  <SmallText></SmallText>
                  <img alt="logo" src={item.image} />
                  <Paragraph size={textSizes.fontSizes.MEDIUM} weight={700}>
                    {item.symbol.toUpperCase()}
                  </Paragraph>
                  <SmallText $color={({ theme }) => theme.colors.LIGHT_TEXT}>
                    {item.coinName}
                  </SmallText>
                </MostComparedCoin>
              </RouteLink>
              {index !== mcData.length - 1 && <VerticalSeperator />}
            </React.Fragment>
          ))}
          <EmptyBox />
          <EmptyBox />
          <EmptyBox />
          {mcData.map((item, index) => (
            <React.Fragment key={index}>
              <RouteLink to={`/coin/${item.id}`}>
                <MostComparedCoin key={index}>
                  <Paragraph size={textSizes.fontSizes.MEDIUM}>
                    {index + 1}
                  </Paragraph>
                  <SmallText></SmallText>
                  <img alt="logo" src={item.image} />
                  <Paragraph size={textSizes.fontSizes.MEDIUM} weight={700}>
                    {item.symbol.toUpperCase()}
                  </Paragraph>
                  <SmallText $color={({ theme }) => theme.colors.LIGHT_TEXT}>
                    {item.coinName}
                  </SmallText>
                </MostComparedCoin>
              </RouteLink>
              {index !== mcData.length - 1 && <VerticalSeperator />}
            </React.Fragment>
          ))}
        </MostComparedCoins>
      </MostComparedContent>

      {
        // <MostComparedAd removed={nameRemoved}>
        //   <RouteLink
        //     target="_blank"
        //     to={"https://comparemarketcap.com/coin/ethereum-bull"}
        //   >
        //     <Row>
        //       <img
        //         style={{ borderRadius: "1000rem" }}
        //         src="https://qwz547u5ood5gucs.public.blob.vercel-storage.com/images/coin-logos/ethbull.webp"
        //       />
        //       <Paragraph
        //         $color={({ theme }) => theme.colors.LIGHT_TEXT}
        //         weight={textSizes.fontWeights.BOLD}
        //       >
        //         EBULL
        //       </Paragraph>
        //     </Row>
        //   </RouteLink>
        // </MostComparedAd>
      }
    </StyledMostCompared>
  );
}
