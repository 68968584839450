import { createGlobalStyle, css } from "styled-components";
import { borderRads, borderWidths } from "./sizes";
import { theme } from "./theme.styled";
import { paddings } from "./paddings.styled";

const GlobalStyle = createGlobalStyle`

.top-right-select-menu {
    position: absolute;
    top: -0.25rem;
    right: 0.25rem;
    z-index: 1;
    max-width: 6rem;

    & > * {
      & > *:nth-child(2) {
        padding: 0.5rem;
        max-width: 5rem;

        & > * {
          gap: 0.25rem;
        }
      }
    }
  }

.react-calendar--selectRange .react-calendar__tile--hover{
  background-color: rgba(255,255,255,0.3);
}

.react-daterange-picker__inputGroup{
  input{
    -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  }
}

.react-calendar__tile:disabled{
  background-color: rgba(0,0,0,0.5);
  opacity: 0.3;

  abbr, span, button{
    opacity: 0.3;
  }
}

.react-calendar__navigation button:disabled{
  background-color: rgba(0,0,0,0.1);
  abbr, span{
    opacity: 0.3;
  }
}

.react-calendar__navigation__label{
  padding-right: ${paddings.LOW};
  padding-left: ${paddings.LOW};  
  background-color: rgba(0,0,0,0.1);
  &:hover{
    background-color: rgba(0,0,0,0.2) !important;
  }
}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus{
  background-color: rgba(255,255,255,0.3);
}

.react-calendar--selectRange, .react-calendar__tile--hover{
  background-color: rgba(255,255,255,0.3);
}

.react-daterange-picker__inputGroup__input{
  //on focus, remove border and outline
  pointer-events: none; 
  &:focus{
    border: none;
    outline: none;
  }
}

.react-daterange-picker__inputGroup__input:invalid{
  background-color: rgba(0,0,0,0.1);
}
.react-calendar__navigation{
  margin-bottom: 0.25rem;
}
.react-daterange-picker__calendar{
  width: 18.5rem;

  left: 50% !important;
  transform: translateX(-50%) !important;

  inset: none !important;
}

.react-calendar__tile{
  padding: 0.25rem;
}

.react-calendar{
  background-color: ${theme.colors.OLDLEVEL2};
  border-radius: ${borderRads.SMALL};
  abbr, span, button{
    text-decoration: none;
  }
}

.react-calendar__tile--now{
  background-color: rgba(255,255,255,0.2)
}

.react-calendar__tile--active{
  background-color: rgba(255,255,255,0.1)

}



.react-daterange-picker__inputGroup{
  min-width: auto !important
}

.react-daterange-picker__wrapper{
  svg{
    stroke: white !important;
  }
}

.react-daterange-picker__inputGroup__input{
  font-size: 0.8rem !important;
}

.react-daterange-picker__clear-button, .react-daterange-picker__button{
  display: none;
}

.react-daterange-picker__wrapper{
  padding: 0.5rem 0.25rem;
  border-color: ${theme.colors.LEVEL2};
  border-radius: ${borderRads.SMALL};
}


.react-datepicker__input-container{
    color: ${({ theme }) => theme.colors.SECONDARY};

    input{
      background-color: ${({ theme }) => theme.colors.SECONDARY};
      color: ${({ theme }) => theme.colors.LIGHT_TEXT};

      //add small border radius and small border
      border-radius: ${borderRads.SMALL};
      border: ${borderWidths.SMALL} solid ${({ theme }) => theme.colors.LEVEL2};
      padding: 0.5rem;
      width: 11.5rem;
      
      text-align: center;

      //remove outline on focus
      &:focus{
        outline: none;
      }
    }
  }

  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: white;
    font-family: ${({ theme }) => theme.fontFamily.DEFAULT};
  }
  body{
    background-color: ${({ theme }) => theme.colors.PRIMARY};
  }
  a{
    text-decoration: none;
  }
  button{
    cursor: pointer;
  }

  ::-moz-selection { /* Code for Firefox */
    color: white;
    background: ${({ theme }) => theme.colors.BLUE};
  }

  ::selection {
    color: white;
    background: ${({ theme }) => theme.colors.BLUE};
  }

  .rcSquareSwiper{
    margin: 0;
    width: 100%;
    height: 15.5rem;
  }

  .promotedCompareSwiper{
    margin: 0;
    width: 100%;
    height: 8rem;

    @media (max-width: 50rem) {
    height: 7.5rem;
  }

    .swiper-pagination-bullet {
    background-color: rgba(255,255,255,0);
  }
  }
  
  .swiper-pagination-bullet-active-color {
    background-color: white !important;
  }

  .swiper-pagination-bullet {
    background-color: rgba(255,255,255,0.5);
    @media(max-width: 40rem){
      width: 0.45rem;
      height: 0.45rem;
    }
  }

  .swiper-pagination-bullets {
    right: 1rem;
    left: auto !important;
    width: fit-content !important;
    @media(max-width: 40rem){
      left: auto;
      right: auto;
      width: 100% !important;
      height: 0.5rem;
      bottom: 0.7rem !important;
    }
  }

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(255,255,255,0.1);
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    border-radius: 1rem;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    max-height: 2rem;
    background: rgba(255,255,255,0.2);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(255,255,255,0.3);
  }
  
  .CookieConsent{
    position: fixed !important;
    background-color: ${({ theme }) => theme.colors.OLDLEVEL} !important;
    border: ${borderWidths.MEDIUM} solid ${({ theme }) => theme.colors.LEVEL};
    right: 1rem !important;
    left: 1rem !important;
    bottom: 1rem !important;
    margin: 0 auto !important;
    width: 90% !important;
    align-items: center !important;
    display: flex !important;
    justify-content: space-between !important;
    border-radius: ${borderRads.SMALL} !important;
    z-index: 1000 !important;
    @media(max-width: 40rem){
      flex-direction: column;
    }
    
    & > *{
      text-align: center;
      flex: none !important;
      margin: 0;
    }
    
    button{
      background-color: ${({ theme }) => theme.colors.BRAND} !important;
      color: white !important;
      border-radius: ${borderRads.SMALL} !important;
    }
  }

  .form-link{
    background-color: ${({ theme }) => theme.colors.LEVEL};

&:hover {
    background-color: ${({ theme }) => theme.colors.LEVEL2};
  }
  }
  
  
  

  .white-on-hover{
    &:hover{
      path{
        color: white;
      }
    }
  }

  .swiper-wrapper {height: 8rem; width: 25rem }
  @media(max-width: 70rem){
    .react-datepicker__current-month{
      font-size: 1.2rem !important;
    }
    .swiper-wrapper {width: 18rem }

    .white-on-hover{
    display: none;
    .dedicated-coin-chart{
      height: auto !important;
    }
  }

  /* .dedicated-coin-chart{
      height: 9rem;
  } */

  .react-datepicker__tab-loop{
    position: relative !important;
  }

  .react-datepicker-popper{
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, 50%) !important;
  }
  }
  
  .react-datepicker{
    color: ${({ theme }) => theme.colors.SECONDARY};

    input{
      background-color: ${({ theme }) => theme.colors.SECONDARY};
      color: ${({ theme }) => theme.colors.LIGHT_TEXT};

      //add small border radius and small border
      border-radius: ${borderRads.SMALL};
      border: ${borderWidths.SMALL} solid ${({ theme }) => theme.colors.LEVEL2};
      padding: 0.5rem;
      width: 11rem;
      
      text-align: center;

      //remove outline on focus
      &:focus{
        outline: none;
      }
    }
  }

  

  .react-datepicker__header{

    background-color: #e6ebf9;
  }

  .holdercomparesinglecoin-link:hover{
    background-color: ${theme.colors.LEVEL2};
    border-radius: ${borderRads.SMALL};
  }


  .compared-mostly-with:hover{
      background-color: ${theme.colors.LEVEL2} !important;
  }

  /* HTML: <div class="loader"></div> */
.holder-compare-loader {
  margin: 0 auto;
  margin-top: 15rem;
  width: 80px;
  aspect-ratio: 1.154;
  clip-path: polygon(50% 0,100% 100%,0 100%);
  --c:no-repeat linear-gradient(#8B8592 0 0);
  background: var(--c),var(--c),var(--c),var(--c),var(--c);
  background-size: 100% calc(100%/5 + 1px);
  animation: l15 2s infinite;
}
@keyframes l15 {
  0%  {background-position: 0 calc(-2*100%/4),0 calc(-2*100%/4),0 calc(-2*100%/4),0 calc(-2*100%/4),0 calc(-2*100%/4)}
  20% {background-position: 0 calc(4*100%/4) ,0 calc(-2*100%/4),0 calc(-2*100%/4),0 calc(-2*100%/4),0 calc(-2*100%/4)}
  40% {background-position: 0 calc(4*100%/4) ,0 calc(3*100%/4) ,0 calc(-2*100%/4),0 calc(-2*100%/4),0 calc(-2*100%/4)}
  60% {background-position: 0 calc(4*100%/4) ,0 calc(3*100%/4) ,0 calc(2*100%/4) ,0 calc(-2*100%/4),0 calc(-2*100%/4)}
  80% {background-position: 0 calc(4*100%/4) ,0 calc(3*100%/4) ,0 calc(2*100%/4) ,0 calc(1*100%/4) ,0 calc(-2*100%/4)}
  100%{background-position: 0 calc(4*100%/4) ,0 calc(3*100%/4) ,0 calc(2*100%/4) ,0 calc(1*100%/4) ,0 calc(0*100%/4)}
}

`;

const zIndexes = {
  MOBILE_NAV_MENU: 6,
  MOST_COMPARED_HEADER: 2,
  DROPDOWN_MENU: 3,
  DROPDOWN_MENU_SEARCH_INPUT: 4,
  SIDEBAR: 5,
  COMPARED_MOSTLY_WITH: 1,
  HOMEPAGE_RECENTLY_LISTED_LINK: 2,
  FOOTER: 2,
  POPUP: 1000,
};

export { GlobalStyle, zIndexes };
