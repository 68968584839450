import React, { useContext } from "react";
import { BiWorld } from "react-icons/bi";
import { FaRedditAlien, FaTelegramPlane } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import { DedicatedCoinContext } from "../../context";
import {
  isBlockChainValid,
  isContractAddressValid,
  isSocialLinkValid,
} from "../../validators";
import { TransparentButton } from "../buttons/buttons.styled";
import { VerticalSeperator } from "../containers/boxes.styled";
import { HideOnMobileWrapper, ShowOnMobileWrapper } from "../hideOnMobile";
import { Icon } from "../icon.styled";
import DedicatedCoinShareButton from "./dedicatedCoinShareButton";
import { StyledSocialMediaSection } from "./socialMediaSection.styled";

export default function SocialMediaSection() {
  const coinData = useContext(DedicatedCoinContext);

  const isMobile = useMediaQuery({
    query: "(max-width: 70rem)",
  });

  let shouldShowChain =
    isContractAddressValid(coinData.contractAddress, coinData.blockChain) &&
    isBlockChainValid(coinData.blockChain) &&
    (coinData.blockChain === "bsc" ||
      coinData.blockChain === "eth" ||
      coinData.blockChain === "base" ||
      coinData.blockChain === "tron" ||
      coinData.blockChain === "solana" ||
      coinData.blockChain === "pulsechain");

  let chainLink = "";
  let chainIconPath = "";

  if (shouldShowChain) {
    setupChain();
  }

  function setupChain() {
    if (coinData.blockChain === "bsc") {
      chainLink = "https://bscscan.com/token/";
      chainIconPath =
        "https://coin-images.coingecko.com/coins/images/825/small/bnb-icon2_2x.png?1696501970";
    } else if (coinData.blockChain === "eth") {
      chainLink = "https://etherscan.io/token/";
      chainIconPath =
        "https://coin-images.coingecko.com/coins/images/279/small/ethereum.png?1696501628";
    } else if (coinData.blockChain === "base") {
      chainLink = "https://basescan.org/token/";
      chainIconPath = "/logos/basescan-logo.png";
    } else if (coinData.blockChain === "solana") {
      chainLink = "https://solscan.io/token/";
      chainIconPath =
        "https://coin-images.coingecko.com/coins/images/4128/small/solana.png?1718769756";
    } else if (coinData.blockChain === "pulsechain") {
      chainLink =
        "https://scan.mypinata.cloud/ipfs/bafybeidn64pd2u525lmoipjl4nh3ooa2imd7huionjsdepdsphl5slfowy/#/token/";
      chainIconPath = "/logos/pulsechain.webp";
    } else if (coinData.blockChain === "tron") {
      chainLink = "https://tronscan.org/#/token20/";
      chainIconPath =
        "https://pbs.twimg.com/profile_images/1761904730668675072/v98T7vRL_400x400.jpg";
    }
    chainLink += coinData.contractAddress;
  }

  return (
    <StyledSocialMediaSection isMobile={isMobile}>
      {shouldShowChain && (
        <React.Fragment>
          <a aria-label="Go to chain." target={"_blank"} href={chainLink}>
            <Icon>
              <img alt="chain" src={chainIconPath} />
            </Icon>
          </a>
          <ShowOnMobileWrapper>
            <VerticalSeperator />
          </ShowOnMobileWrapper>
        </React.Fragment>
      )}

      {isSocialLinkValid(coinData.web) && (
        <a
          aria-label="Go to website."
          href={
            coinData.web.slice(0, 8) === "https://"
              ? coinData.web
              : `https://${coinData.web}`
          }
          target={"_blank"}
        >
          <TransparentButton>
            <Icon color={({ theme }) => theme.colors.LIGHT_TEXT}>
              <BiWorld />
            </Icon>
          </TransparentButton>
        </a>
      )}

      {isSocialLinkValid(coinData.twitter) && (
        <a
          aria-label="Go to X profile."
          href={
            coinData.source === "ds"
              ? coinData.twitter
              : `https://x.com/${coinData.twitter.toString()}`
          }
          target={"_blank"}
        >
          <TransparentButton>
            <Icon color={({ theme }) => theme.colors.LIGHT_TEXT}>
              <svg
                version="1.1"
                id="svg5"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 1668.56 1221.19"
                xmlSpace="preserve"
              >
                <g id="layer1" transform="translate(52.390088,-25.058597)">
                  <path
                    id="path1009"
                    d="M283.94,167.31l386.39,516.64L281.5,1104h87.51l340.42-367.76L984.48,1104h297.8L874.15,558.3l361.92-390.99
                    h-87.51l-313.51,338.7l-253.31-338.7H283.94z M412.63,231.77h136.81l604.13,807.76h-136.81L412.63,231.77z"
                  />
                </g>
              </svg>
            </Icon>
          </TransparentButton>
        </a>
      )}

      {isSocialLinkValid(coinData.telegram) && (
        <a
          aria-label="Go to Telegram."
          href={`https://t.me/${coinData.telegram}`}
          target={"_blank"}
        >
          <TransparentButton>
            <Icon color={({ theme }) => theme.colors.LIGHT_TEXT}>
              <FaTelegramPlane />
            </Icon>
          </TransparentButton>
        </a>
      )}

      {isSocialLinkValid(coinData.reddit) && (
        <a
          aria-label="Go to Reddit."
          href={`https://www.reddit.com/r/${coinData.reddit}/`}
          target={"_blank"}
        >
          <TransparentButton>
            <Icon color={({ theme }) => theme.colors.LIGHT_TEXT}>
              <FaRedditAlien />
            </Icon>
          </TransparentButton>
        </a>
      )}

      {!isMobile && (
        <React.Fragment>
          <VerticalSeperator />

          <DedicatedCoinShareButton coinName={coinData.coinName} />
        </React.Fragment>
      )}
    </StyledSocialMediaSection>
  );
}
