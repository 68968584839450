import styled from "styled-components";
import { paddings } from "../paddings.styled";
import { textSizes } from "../sizes";

export const SwitchOption = styled.button`
  background-color: ${({ theme, selected, selectedBackgroundColor }) =>
    selected
      ? selectedBackgroundColor
        ? selectedBackgroundColor
        : theme.colors.LEVEL2
      : "transparent"};
  border: none;
  padding: ${paddings.HIGH};

  white-space: nowrap;

  font-weight: ${({ selected }) => selected && textSizes.fontWeights.SEMI_BOLD};
  display: flex;
  align-items: center;
  justify-content: center;

  line-height: 1rem;
  height: 100%;

  img {
    height: 1rem;
    position: center;
    filter: grayscale(1);
  }
`;
