import styled from "styled-components";
import {
  Container,
  HorizontalSeperator,
  VerticalSeperator,
} from "../containers/boxes.styled";
import { Column, FullRow, Row } from "../containers/flexbox.styled";
import React, { act, useContext, useState } from "react";
import { HolderCompareCoinsContext } from "../../context";
import { BigText, Header, Paragraph, SmallText } from "../texts.styled";
import { Icon } from "../icon.styled";
import {
  MdOutlineExpand,
  MdOutlineExpandLess,
  MdOutlineExpandMore,
} from "react-icons/md";
import { PrimaryButton, TransparentButton } from "../buttons/buttons.styled";
import { theme } from "../theme.styled";
import { paddings } from "../paddings.styled";
import { borderRads, borderWidths, textSizes } from "../sizes";
import DoubleDonutChart from "./donutChart";
import { track } from "@vercel/analytics/react";
import Dropdown from "../compareSection/dropdown";
import { ClickAwayListener } from "@mui/base";
import { set } from "date-fns";
import { RouteLink } from "../../routing/routeLink";
import { IoPerson } from "react-icons/io5";
import HolderDistChartWrapper from "./holderDistChart";
import SelectMenu from "../misc/selectMenu";
import {
  StyledDedicatedCoinBottomBar,
  StyledDedicatedCoinBottomBarPage,
} from "../../pages/dedicatedCoinNew";
import BarChartWrapper from "../dedicatedCoin/simpleChartBar";
import {
  formatNumber,
  getSmallNumber,
  graphFormatter,
} from "../../util/numberFormatter";
import {
  HolderCompareDesktopHolderDist,
  StyledCommonHoldersDesktop,
} from "./holderCompareDesktop";
import Switch from "../misc/switch";
import MultiSwitch from "../misc/multiSwitch";
import { getHolderCompareList } from "../stream";
import { useQuery } from "@tanstack/react-query";
import Loader from "../misc/loader";
import { PiEmpty } from "react-icons/pi";
import MostCompared from "../misc/mostCompared/mostCompared";
import {
  StyledCoinRoiTable,
  StyledHolderCompareLevelsTable,
} from "../dedicatedCoin/coinRoi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SimpleToggle from "../misc/simpleToogle";
import { HideOnMobileWrapper } from "../hideOnMobile";

//empty react component
export default function HolderCompareMobile() {
  const [activePage, setActivePage] = useState(0);
  const { coin1Data, coin2Data, compareData } = useContext(
    HolderCompareCoinsContext
  );

  return (
    <StyledHolderCompareMobile activePage={activePage}>
      <MostCompared />
      <HolderCompareCoins />
      <CommonHoldersMobile />
      <CommonHoldersChart />
      <HolderSizeTable />
      {activePage === 1 && (
        <>
          <Header
            size="1rem"
            weight={textSizes.fontWeights.SEMI_BOLD}
            style={{ margin: "0.2rem auto 0.2rem auto" }}
          >
            Holder Distributions
          </Header>
          <HolderCompareDesktopHolderDist
            coin={coin1Data}
            coinNo={1}
            isMobile={true}
          />

          <HolderCompareDesktopHolderDist
            coin={coin2Data}
            coinNo={2}
            isMobile={true}
          />

          <Header
            size="1rem"
            weight={textSizes.fontWeights.SEMI_BOLD}
            style={{ margin: "0.5rem auto 0.2rem auto" }}
          >
            Holder Changes
          </Header>

          <BarChartWrapper
            coinId={coin1Data.id}
            coin={coin1Data}
            key={coin1Data.id + "mob"}
            isHoldersMobile={true}
          />

          <BarChartWrapper
            coinId={coin2Data.id}
            coin={coin2Data}
            key={coin2Data.id + "mob"}
            isHoldersMobile={true}
          />
        </>
      )}

      {activePage === 2 &&
        (compareData.commonHolders.length > 0 ? (
          <>
            <CommonHoldersTable />
            <div></div>
          </>
        ) : (
          <Column justify="center" gap="1rem" style={{ margin: "15rem auto" }}>
            <Icon color={theme.colors.LIGHT_TEXT} size="5rem">
              <PiEmpty />
            </Icon>
            <Paragraph color={theme.colors.LIGHT_TEXT}>
              No common holders.
            </Paragraph>
          </Column>
        ))}

      <StyledDedicatedCoinBottomBar>
        <FullRow justify="space-between">
          <StyledDedicatedCoinBottomBarPage
            isActive={activePage === 0}
            onClick={() => {
              setActivePage(0);
            }}
          >
            ANALYSIS
          </StyledDedicatedCoinBottomBarPage>
          <StyledDedicatedCoinBottomBarPage
            isActive={activePage === 1}
            onClick={() => {
              setActivePage(1);
            }}
          >
            CHARTS
          </StyledDedicatedCoinBottomBarPage>
          <StyledDedicatedCoinBottomBarPage
            isActive={activePage === 2}
            onClick={() => {
              setActivePage(2);
            }}
          >
            WALLETS
          </StyledDedicatedCoinBottomBarPage>
        </FullRow>
      </StyledDedicatedCoinBottomBar>
    </StyledHolderCompareMobile>
  );
}

const StyledHolderCompareMobile = styled(Container)`
  @media (min-width: 50rem) {
    display: none;
  }
  width: 100%;

  //if smaller than 70rem, and activepage is not 2, add 0.75 padding
  padding: ${({ activePage }) => activePage !== 2 && paddings.MID};

  & > :nth-child(-n + 5) {
    display: ${({ activePage }) => activePage !== 0 && "none"};
  }

  & > :last-child {
    display: flex;
  }
`;

export function HolderSizeTableDesktop({ type }) {
  const { coin1Data, coin2Data, compareData } = useContext(
    HolderCompareCoinsContext
  );

  return (
    <StyledHolderSizeTableDesktop>
      <div className="section">
        <SmallText>
          {type === "common" ? "Common holders" : "Holders"} <br /> with over
        </SmallText>
      </div>
      <div className="section">
        <SmallText color={theme.colors.LIGHT_TEXT}>$10</SmallText>
        <SmallText>
          {graphFormatter(compareData[`${type}MoreThan10`])}
        </SmallText>
      </div>
      <VerticalSeperator color={"rgba(255,255,255,0.1)"} height={"70%"} />
      <div className="section">
        <SmallText color={theme.colors.LIGHT_TEXT}>$100</SmallText>
        <SmallText>
          {" "}
          {graphFormatter(compareData[`${type}MoreThan100`])}
        </SmallText>
      </div>
      <VerticalSeperator color={"rgba(255,255,255,0.1)"} height={"70%"} />
      <div className="section">
        <SmallText color={theme.colors.LIGHT_TEXT}>$1,000</SmallText>
        <SmallText>
          {" "}
          {graphFormatter(compareData[`${type}MoreThan1000`])}
        </SmallText>
      </div>
      <VerticalSeperator color={"rgba(255,255,255,0.1)"} height={"70%"} />
      <div className="section">
        <SmallText color={theme.colors.LIGHT_TEXT}>$10,000</SmallText>
        <SmallText>
          {" "}
          {graphFormatter(compareData[`${type}MoreThan10000`])}
        </SmallText>
      </div>
    </StyledHolderSizeTableDesktop>
  );
}

const StyledHolderSizeTableDesktop = styled(FullRow)`
  background-color: ${theme.colors.LEVEL};
  border-radius: ${borderRads.SMALL};

  height: 3.5rem;

  overflow: hidden;

  gap: 0;

  .section {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.25rem;
    padding: 0 0.5rem;
    & > *:first-child {
      font-weight: ${textSizes.fontWeights.REGULAR};
    }
  }

  .section:first-child {
    & > * {
      font-weight: ${textSizes.fontWeights.REGULAR};
    }
    white-space: nowrap;
    width: fit-content;
    background-color: ${theme.colors.LEVEL};
    padding: 0 1rem;
  }
`;

export function HolderSizeTable() {
  const { coin1Data, coin2Data, compareData } = useContext(
    HolderCompareCoinsContext
  );

  return (
    <StyledHolderCompareLevelsTable isHolders="true">
      <tr>
        <td style={{ whiteSpace: "nowrap", minWidth: "7rem" }}>
          Holders <br /> with over
        </td>
        <td
          style={{
            width: "33%",
          }}
        >
          <img
            style={{ width: "1.65rem" }}
            src={coin1Data.image}
            alt={coin1Data.coinName}
          />
        </td>
        <td
          style={{
            width: "33%",
          }}
        >
          <img
            style={{ width: "1.65rem" }}
            src={coin2Data.image}
            alt={coin2Data.coinName}
          />
        </td>
        <td
          style={{
            opacity: 0.75,
            width: "33%",
          }}
        >
          Common
        </td>
      </tr>

      <tr>
        <td
          style={{
            opacity: 0.75,
          }}
        >
          {">$10"}
        </td>
        <td>{graphFormatter(compareData.coin1MoreThan10)}</td>
        <td>{graphFormatter(compareData.coin2MoreThan10)}</td>
        <td>{graphFormatter(compareData.commonMoreThan10)}</td>
      </tr>
      <tr>
        <td
          style={{
            opacity: 0.75,
          }}
        >
          {">$100"}
        </td>
        <td>{graphFormatter(compareData.coin1MoreThan100)}</td>
        <td>{graphFormatter(compareData.coin2MoreThan100)}</td>
        <td>{graphFormatter(compareData.commonMoreThan100)}</td>
      </tr>
      <tr>
        <td
          style={{
            opacity: 0.75,
          }}
        >
          {">$1,000"}
        </td>
        <td>{graphFormatter(compareData.coin1MoreThan1000)}</td>
        <td>{graphFormatter(compareData.coin2MoreThan1000)}</td>
        <td>{graphFormatter(compareData.commonMoreThan1000)}</td>
      </tr>
      <tr>
        <td
          style={{
            opacity: 0.75,
          }}
        >
          {">$10,000"}
        </td>
        <td>{graphFormatter(compareData.coin1MoreThan10000)}</td>
        <td>{graphFormatter(compareData.coin2MoreThan10000)}</td>
        <td>{graphFormatter(compareData.commonMoreThan10000)}</td>
      </tr>
    </StyledHolderCompareLevelsTable>
  );
}

export function HolderCompareCoins() {
  const { coin1Data, coin2Data } = useContext(HolderCompareCoinsContext);

  const [isMenuOn, setIsMenuOn] = useState(false);

  const [dropdownCoin1, setDropdownCoin1] = useState(coin1Data);
  const [dropdownCoin2, setDropdownCoin2] = useState(coin2Data);

  const navigate = useNavigate();

  const { data: holderList, status } = useQuery(
    ["getHolderCoinsList"],
    () => getHolderCompareList(),
    {
      staleTime: Infinity,
      onSuccess: (data) => {},
    }
  );

  const notify = (message) =>
    toast(message || "Please choose two different coins.");

  return (
    <StyledHolderCompareCoins justify="space-between" isMenuOn={isMenuOn}>
      <FullRow
        onClick={() => {
          setIsMenuOn(true);
        }}
        justify="space-between"
        style={{
          cursor: "pointer",
        }}
      >
        <Row gap="0.5rem" width="5rem" justify="start">
          <img
            style={{ width: "1.8rem", userSelect: "none" }}
            src={coin1Data.image}
            alt={coin1Data.coinName}
          />
          <Header
            style={{
              userSelect: "none",
            }}
            size={"1.2rem"}
          >
            {coin1Data.symbol.toUpperCase()}
          </Header>
        </Row>
        <Icon size="1.5rem">
          {isMenuOn ? <MdOutlineExpandLess /> : <MdOutlineExpandMore />}
        </Icon>
        <Row gap="0.5rem" width="5rem" justify="end">
          <Header
            style={{
              userSelect: "none",
            }}
            size={"1.2rem"}
          >
            {coin2Data.symbol.toUpperCase()}
          </Header>
          <img
            style={{ width: "1.8rem", userSelect: "none" }}
            src={coin2Data.image}
            alt={coin2Data.coinName}
          />
        </Row>
      </FullRow>

      {isMenuOn && (
        <ClickAwayListener
          onClickAway={(e) => {
            setIsMenuOn(false);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <CompareAgainMenu>
            {holderList ? (
              <>
                <SmallText>CHANGE COINS</SmallText>
                <Dropdown
                  items={holderList}
                  isHolderCompare={true}
                  setCoin={setDropdownCoin1}
                  defaultExternalCoin={coin1Data}
                />
                <SmallText>vs</SmallText>
                <Dropdown
                  items={holderList.filter(
                    (item) =>
                      item.group === dropdownCoin1.group &&
                      item.id !== dropdownCoin1.id
                  )}
                  isHolderCompare={true}
                  setCoin={setDropdownCoin2}
                  chainCheckCoin={dropdownCoin1}
                  defaultExternalCoin={coin2Data}
                />

                <PrimaryButton
                  style={{ padding: `${paddings.LOW} ${paddings.MID}` }}
                  onClick={() => {
                    navigate(
                      `/holder-compare/${dropdownCoin1.id}/${dropdownCoin2.id}`
                    );
                    setIsMenuOn(false);
                  }}
                >
                  <Paragraph>Compare</Paragraph>
                </PrimaryButton>
              </>
            ) : (
              <Loader height={"10rem"} isSmall={true} />
            )}
          </CompareAgainMenu>
        </ClickAwayListener>
      )}
    </StyledHolderCompareCoins>
  );
}

const CompareAgainMenu = styled(Column)`
  position: absolute;
  gap: 1rem;
  top: 100%;
  right: 0;
  left: 0;
  background-color: ${theme.colors.OLDLEVEL};
  padding: ${paddings.MID};
  border-radius: 0 0 1.5rem 1.5rem;
  border: ${borderWidths.SMALL} solid ${theme.colors.LEVEL};
  z-index: 2;
`;

const StyledHolderCompareCoins = styled(FullRow)`
  background-color: ${theme.colors.LEVEL2};
  padding: ${paddings.LOW} ${paddings.HIGH};
  border-radius: ${({ isMenuOn }) =>
    isMenuOn ? "1.35rem 1.35rem 0 0" : "1.5rem"};

  &:hover {
    background-color: ${theme.colors.LEVEL};
  }
`;

export function CommonHoldersMobile() {
  const { coin1Data, coin2Data, compareData, excludeCex } = useContext(
    HolderCompareCoinsContext
  );

  return (
    <StyledCommonHoldersDesktop justify="space-between">
      <FullRow justify="space-between" style={{ padding: "0.75rem" }}>
        <Column align="start" gap="0.25rem" width="5rem">
          <img src={coin1Data.image} alt={coin1Data.coinName} />
          <Paragraph>{formatNumber(compareData.coin1HolderCount)}</Paragraph>
        </Column>
        <Column width="5rem">
          <SmallText color={theme.colors.LIGHT_TEXT}>COMMON</SmallText>
          <Paragraph size={"1.6rem"} weight={textSizes.fontWeights.MEDIUM}>
            {formatNumber(compareData[`topAllCommonCount`])}
          </Paragraph>
        </Column>
        <Column align="end" gap="0.25rem" width="5rem">
          <img src={coin2Data.image} alt={coin2Data.coinName} />
          <Paragraph>{formatNumber(compareData.coin2HolderCount)}</Paragraph>
        </Column>
      </FullRow>
      <HorizontalSeperator width={"100%"} color={"rgba(255,255,255,0.3)"} />
      <FullRow justify="space-between" style={{ padding: "0.5rem 0.75rem" }}>
        <Paragraph style={{ width: "5rem" }}>
          ${graphFormatter(coin1Data.marketcap)}
        </Paragraph>
        <SmallText color={theme.colors.LIGHT_TEXT}>MCAP</SmallText>
        <Paragraph style={{ width: "5rem", textAlign: "end" }}>
          ${graphFormatter(coin2Data.marketcap)}
        </Paragraph>
      </FullRow>
      <FullRow
        justify="space-between"
        style={{ padding: "0.5rem 0.75rem", marginTop: "-0.2rem" }}
      >
        <Paragraph style={{ width: "5rem" }}>
          $
          {formatNumber(
            coin1Data.marketcap / compareData.coin1HolderCount,
            true,
            false,
            false,
            6,
            true
          )}
        </Paragraph>
        <SmallText color={theme.colors.LIGHT_TEXT}>MCAP/HOLDERS</SmallText>
        <Paragraph style={{ width: "5rem", textAlign: "end" }}>
          $
          {formatNumber(
            coin2Data.marketcap / compareData.coin2HolderCount,
            true,
            false,
            false,
            6,
            true
          )}
        </Paragraph>
      </FullRow>
    </StyledCommonHoldersDesktop>
  );
}

const StyledCommonHoldersMobile = styled(FullRow)`
  padding: ${paddings.LOW} ${paddings.MID};
  background-color: ${theme.colors.LEVEL};
  border-radius: ${borderRads.SMALL};

  border: ${borderWidths.SMALL} solid #0097d1;

  ${Column} {
    height: 100%;
    justify-content: space-between;
  }

  img {
    width: 1.2rem;
  }
`;

export function CommonHoldersChart({ isDesktop }) {
  const { coin1Data, coin2Data, excludeCex, setExcludeCex } = useContext(
    HolderCompareCoinsContext
  );

  return (
    <StyledCommonHoldersChart>
      <div
        style={{
          position: "absolute",
          top: "0.5rem",
          left: "0.5rem",
        }}
      >
        <Column gap="0.25rem" align="start">
          <SimpleToggle
            onTrue={() => {
              setExcludeCex(true);
            }}
            onFalse={() => {
              setExcludeCex(false);
            }}
            excludeCex={excludeCex}
          />
          <SmallText
            color={theme.colors.LIGHT_TEXT}
            size={textSizes.fontSizes.SMALLER}
          >
            Exclude <br className="show-on-mobile" />
            CEX, DEX
          </SmallText>
        </Column>
      </div>
      <Row
        gap="0.75em"
        justify="center"
        style={{
          backgroundColor: theme.colors.LEVEL,
          padding: "0.5rem",
          borderRadius: "0.5rem",
        }}
        width="fit-content !important"
      >
        <RouteLink to={`/coin/${coin2Data.id}`}>
          <img src={coin2Data.image} alt={coin2Data.coinName} />
        </RouteLink>
        <SmallText>holders in</SmallText>
        <RouteLink to={`/coin/${coin1Data.id}`}>
          <img src={coin1Data.image} alt={coin1Data.coinName} />
        </RouteLink>
      </Row>
      <FullRow
        justify="center"
        gap={isDesktop ? "2rem" : "1rem"}
        style={isDesktop ? { justifyContent: "center" } : {}}
      >
        <DoubleDonutChart isDesktop={isDesktop} />
        <ChartData isDesktop={isDesktop} />
      </FullRow>
      {!isDesktop && (
        <SmallText
          color={"rgba(255,255,255,0.5)"}
          size={"0.7rem"}
          style={{ textAlign: "center", marginTop: "-0.25rem" }}
        >
          *This comparison was made based <br /> on wallets with balance more
          than $5.
        </SmallText>
      )}
    </StyledCommonHoldersChart>
  );
}

export function ChartData({ isDesktop }) {
  const { coin1Data, coin2Data, compareData, excludeCex } = useContext(
    HolderCompareCoinsContext
  );

  console.log(compareData);

  return (
    <StyledCommonHoldersChartData
      gap="0.5rem"
      align="start"
      isDesktop={isDesktop}
    >
      <Row style={{ marginBottom: "0.5rem" }}>
        <img
          style={{ width: "1rem" }}
          src={coin1Data.image}
          alt={coin1Data.coinName}
        />
        <SmallText color={theme.colors.LIGHT_TEXT}>Supply:</SmallText>
        <SmallText>
          {graphFormatter(
            compareData[`coin1CircSupply${excludeCex ? "NoCex" : ""}`]
          )}
        </SmallText>
      </Row>

      <Column>
        <Row gap="0.5rem">
          <svg
            style={{ borderRadius: "2rem" }}
            width="1rem"
            height="1rem"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="1rem" height="1rem" fill="#AAE600" />
          </svg>

          <Row gap="0.4rem">
            <SmallText color={theme.colors.LIGHT_TEXT}>Top 100</SmallText>
            <img
              style={{ width: "1rem" }}
              src={coin2Data.image}
              alt={coin2Data.coinName}
            />
            <SmallText color={theme.colors.LIGHT_TEXT}>holders</SmallText>
          </Row>
        </Row>
        <Row gap="1.5rem">
          <SmallText>
            {graphFormatter(
              compareData[`amountTop100Total${excludeCex ? "NoCex" : ""}`]
            )}
          </SmallText>
          <Row gap="0.25rem">
            <SmallText color={theme.colors.LIGHT_TEXT}>
              {graphFormatter(
                compareData[`top100CommonCount${excludeCex ? "NoCex" : ""}`]
              )}
            </SmallText>
            <Icon color={theme.colors.LIGHT_TEXT}>
              <IoPerson />
            </Icon>
          </Row>
        </Row>
      </Column>

      <Column>
        <Row gap="0.5rem">
          <svg
            style={{ borderRadius: "2rem" }}
            width="1rem"
            height="1rem"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="1rem" height="1rem" fill="#0097D1" />
          </svg>

          <Row gap="0.4rem">
            <SmallText color={theme.colors.LIGHT_TEXT}>All</SmallText>
            <img
              style={{ width: "1rem" }}
              src={coin2Data.image}
              alt={coin2Data.coinName}
            />
            <SmallText color={theme.colors.LIGHT_TEXT}>holders</SmallText>
          </Row>
        </Row>
        <Row gap="1.5rem">
          <SmallText>
            {graphFormatter(
              compareData[`amountAllCommonTotal${excludeCex ? "NoCex" : ""}`]
            )}
          </SmallText>
          <Row gap="0.25rem">
            <SmallText color={theme.colors.LIGHT_TEXT}>
              {graphFormatter(
                compareData[`topAllCommonCount${excludeCex ? "NoCex" : ""}`]
              )}
            </SmallText>
            <Icon color={theme.colors.LIGHT_TEXT}>
              <IoPerson />
            </Icon>
          </Row>
        </Row>
      </Column>
    </StyledCommonHoldersChartData>
  );
}

export function HolderDistCharts() {
  const { coin1Data, coin2Data } = useContext(HolderCompareCoinsContext);

  const [top, setTop] = useState("Top 10");

  return (
    <StyledHolderDistCharts>
      <Column gap="0.1rem">
        <SmallText>Holder Distribution</SmallText>
        <SmallText size={"0.75rem"} color={theme.colors.LIGHT_TEXT}>
          How much the top holders hold?
        </SmallText>
      </Column>
      <div className="top-right-select-menu">
        <SelectMenu
          options={[
            { item: "Top 10", degree: 0 },
            { item: "Top 50", degree: 0 },
            { item: "Top 100", degree: 0 },
          ]}
          selected={top}
          setSelected={setTop}
        />
      </div>
      <FullRow justify="space-evenly">
        <HolderDistChartWrapper
          coin={coin1Data}
          key={coin1Data.id}
          top={top}
          coinNo={1}
        />
        <HolderDistChartWrapper
          coin={coin2Data}
          key={coin2Data.id}
          top={top}
          coinNo={2}
        />
      </FullRow>
    </StyledHolderDistCharts>
  );
}

const StyledHolderDistCharts = styled(Column)`
  background-color: ${theme.colors.LEVEL};
  border-radius: ${borderRads.SMALL};
  width: 100%;
  gap: 1.5rem;
  //under 70rem, make it padding 0.5rem
  @media (max-width: 70rem) {
    padding: 0.5rem;
    gap: 0.5rem;
  }
`;

const StyledCommonHoldersChartData = styled(Column)`
  ${SmallText} {
    white-space: nowrap;
  }
  width: ${({ isDesktop }) => (isDesktop ? "fit-content" : "100%")};

  max-width: 12rem;

  background-color: ${({ isDesktop }) =>
    isDesktop ? "transparent" : theme.colors.LEVEL};
  padding: ${({ isDesktop }) => (isDesktop ? 0 : paddings.MID)};
  border-radius: ${borderRads.SMALL};
  gap: ${({ isDesktop }) => (isDesktop ? "0.75rem" : "1rem")};
  & > * {
    gap: 0.5rem;
    & > *:first-child {
      gap: 0.5rem;
    }
    & > *:nth-child(2) {
      font-size: 0.9rem;
    }
  }

  //for each child except the first one, align items start

  & > *:nth-child(n + 2) {
    align-items: start;
  }
`;

const StyledCommonHoldersChart = styled(Column)`
  & > *:nth-child(2) {
    img {
      width: 1.7rem;
    }
  }

  gap: 0.8rem;

  @media (max-width: 70rem) {
    gap: 1.1rem;
  }

  .show-on-mobile {
    display: none;

    @media (max-width: 40rem) {
      display: block;
    }
  }

  width: 100%;
  background-color: ${theme.colors.LEVEL};
  padding: ${paddings.MID};
  border-radius: ${borderRads.SMALL};
  //add border
  border: 0.11rem solid ${theme.colors.LEVEL2};
`;

export function CommonHoldersTable({ isDesktop }) {
  const { coin1Data, coin2Data, compareData, excludeCex, setExcludeCex } =
    useContext(HolderCompareCoinsContext);

  const [filter, setFilter] = useState("Top 10");

  const [sort, setSort] = useState(coin1Data.symbol.toUpperCase());

  const filterOptions = [
    { item: "All", onClick: () => setFilter("All"), name: "All" },
    { item: "Top 10", onClick: () => setFilter("Top 10"), name: "Top 10" },
    { item: "Top 50", onClick: () => setFilter("Top 50"), name: "Top 50" },
    { item: "Top 100", onClick: () => setFilter("Top 100"), name: "Top 100" },
  ];

  const [page, setPage] = useState(1);

  const totalPages = Math.ceil(compareData.commonHolders.length / 20);

  return (
    <Column
      align="center"
      width="100%"
      gap="1rem"
      style={{ marginTop: "1rem" }}
    >
      {/* <Row gap="1rem">
        <MultiSwitch
          selectedColor={theme.colors.LEVEL2}
          options={filterOptions}
          adaptiveWidth={true}
          startSelected
          isWallets={true}
        />
      </Row> */}

      <FullRow gap="0.25rem" justify="center">
        <SmallText
          color={theme.colors.LIGHT_TEXT}
          size={textSizes.fontSizes.SMALLER}
        >
          Exclude CEX, DEX
        </SmallText>
        <SimpleToggle
          onTrue={() => {
            setExcludeCex(true);
          }}
          onFalse={() => {
            setExcludeCex(false);
          }}
          excludeCex={excludeCex}
        />
      </FullRow>

      <FullRow justify="center" gap="1rem">
        {/* First Page Button */}
        <PaginationButton onClick={() => setPage(1)} disabled={page === 1}>
          1
        </PaginationButton>

        {/* Left Ellipsis */}
        {page > 3 && <Paragraph disabled>...</Paragraph>}

        {/* Dynamic Middle Page PaginationButtons */}
        {Array.from({ length: isDesktop ? 5 : 3 }, (_, i) => {
          const startPage = Math.max(2, Math.min(page - 2, totalPages - 4));
          const middlePage = startPage + i;

          // Do not show the first or last pages here
          if (middlePage >= totalPages || middlePage === 1) return null;

          return (
            <PaginationButton
              key={middlePage}
              onClick={() => setPage(middlePage)}
              disabled={page === middlePage}
            >
              {middlePage}
            </PaginationButton>
          );
        })}

        {/* Right Ellipsis */}
        {page < totalPages - 2 && <Paragraph disabled>...</Paragraph>}

        {/* Last Page PaginationButton */}
        <PaginationButton
          onClick={() => setPage(totalPages)}
          disabled={page === totalPages}
        >
          {totalPages}
        </PaginationButton>
      </FullRow>

      {/* <FullRow justify="center" gap="1rem">
        <SmallText style={{ whiteSpace: "nowrap" }}>Sort by:</SmallText>
        <Row gap="1rem" width="fit-content !important">
          <Row gap="0.25rem" style={{ cursor: "pointer" }}>
            <img
              src={coin1Data.image}
              alt={coin1Data.coinName}
              style={{
                width: "1rem",
                filter:
                  sort === coin1Data.symbol.toUpperCase()
                    ? "none"
                    : "grayscale(100%)",
              }}
            />
            <SmallText
              onClick={() => setSort(coin1Data.symbol.toUpperCase())}
              style={{ whiteSpace: "nowrap" }}
              weight={
                sort === coin1Data.symbol.toUpperCase()
                  ? textSizes.fontWeights.BOLD
                  : textSizes.fontWeights.LIGHT
              }
            >
              {coin1Data.symbol.toUpperCase()}
            </SmallText>
          </Row>

          <Row gap="0.25rem" style={{ cursor: "pointer" }}>
            <img
              src={coin2Data.image}
              alt={coin2Data.coinName}
              style={{
                width: "1rem",
                filter:
                  sort === coin2Data.symbol.toUpperCase()
                    ? "none"
                    : "grayscale(100%)",
              }}
            />
            <SmallText
              onClick={() => setSort(coin2Data.symbol.toUpperCase())}
              style={{ whiteSpace: "nowrap" }}
              weight={
                sort === coin2Data.symbol.toUpperCase()
                  ? textSizes.fontWeights.BOLD
                  : textSizes.fontWeights.LIGHT
              }
            >
              {coin2Data.symbol.toUpperCase()}
            </SmallText>
          </Row>
        </Row>
      </FullRow> */}

      <StyledCommonHoldersTable isDesktop={isDesktop}>
        <thead>
          <tr>
            <th>
              <Paragraph>#</Paragraph>
            </th>
            <th>
              <Paragraph>Holders</Paragraph>
            </th>
            <th>
              <FullRow justify="center" gap="0.5rem">
                <img
                  style={{ width: "1.5rem" }}
                  src={coin1Data.image}
                  alt={coin1Data.coinName}
                />
                <Paragraph weight={textSizes.fontWeights.SEMI_BOLD}>
                  {coin1Data.symbol.toUpperCase()}
                </Paragraph>
              </FullRow>
            </th>
            <th>
              <FullRow justify="center" gap="0.5rem">
                <img
                  style={{ width: "1.5rem" }}
                  src={coin2Data.image}
                  alt={coin2Data.coinName}
                />
                <Paragraph weight={textSizes.fontWeights.SEMI_BOLD}>
                  {coin2Data.symbol.toUpperCase()}
                </Paragraph>
              </FullRow>
            </th>
          </tr>
        </thead>
        <tbody>
          {compareData.commonHolders
            .filter((holder) => {
              //if excludeCex, remove out the ones without owner field
              if (excludeCex) {
                return !holder.owner;
              }
              return true;
            })
            .slice(
              (page - 1) * 20,
              Math.min(page * 20, compareData.commonHolders.length)
            )
            /* .sort((a, b) => {
              if (sort === coin1Data.symbol.toUpperCase()) {
                return b.percentage1 - a.percentage1;
              } else {
                return b.percentage2 - a.percentage2;
              }
            }) */
            .map((holder, index) => (
              <tr key={index}>
                <td>
                  <Paragraph>{index + 1 + (page - 1) * 20}</Paragraph>
                </td>
                <td>
                  <Paragraph color={theme.colors.LIGHT_TEXT}>
                    {holder.owner || holder.walletaddress}
                  </Paragraph>
                </td>
                <td>
                  <Paragraph
                    weight={textSizes.fontWeights.SEMI_BOLD}
                    color={
                      holder.percentage1 > holder.percentage2
                        ? theme.colors.GREEN
                        : "white"
                    }
                  >
                    {isDesktop && (
                      <span
                        style={{
                          fontWeight: textSizes.fontWeights.LIGHT,
                          color: theme.colors.LIGHT_TEXT,
                        }}
                      >
                        $
                        {isDesktop
                          ? formatNumber(holder.balance1)
                          : graphFormatter(holder.balance1)}{" "}
                      </span>
                    )}
                    {isDesktop ? (
                      holder.percentage1 > 0.001 ? (
                        `${formatNumber(holder.percentage1, true)}%`
                      ) : (
                        <React.Fragment>
                          <span>0.0</span>
                          <SmallText
                            size={textSizes.fontSizes.MOBILE_LITTLE_NUMBER}
                          >
                            {getSmallNumber(holder.percentage1).zeroCount}
                          </SmallText>
                          <span>
                            {getSmallNumber(holder.percentage1).nonZeroDigits}%
                          </span>
                        </React.Fragment>
                      )
                    ) : holder.percentage1 > 0.001 ? (
                      `${formatNumber(holder.percentage1, true)}%`
                    ) : (
                      <React.Fragment>
                        <span>0.0</span>
                        <SmallText
                          size={textSizes.fontSizes.MOBILE_LITTLE_NUMBER}
                        >
                          {getSmallNumber(holder.percentage1).zeroCount}
                        </SmallText>
                        <span>
                          {getSmallNumber(holder.percentage1).nonZeroDigits}%
                        </span>
                      </React.Fragment>
                    )}
                  </Paragraph>
                </td>
                <td>
                  <Paragraph
                    weight={textSizes.fontWeights.SEMI_BOLD}
                    color={
                      holder.percentage2 > holder.percentage1
                        ? theme.colors.GREEN
                        : "white"
                    }
                  >
                    {isDesktop && (
                      <span
                        style={{
                          fontWeight: textSizes.fontWeights.LIGHT,
                          color: theme.colors.LIGHT_TEXT,
                        }}
                      >
                        $
                        {isDesktop
                          ? formatNumber(holder.balance2)
                          : graphFormatter(holder.balance2)}{" "}
                      </span>
                    )}
                    {isDesktop ? (
                      holder.percentage2 > 0.001 ? (
                        `${formatNumber(holder.percentage2, true)}%`
                      ) : (
                        <React.Fragment>
                          <span>0.0</span>
                          <SmallText
                            size={textSizes.fontSizes.MOBILE_LITTLE_NUMBER}
                          >
                            {getSmallNumber(holder.percentage2).zeroCount}
                          </SmallText>
                          <span>
                            {getSmallNumber(holder.percentage2).nonZeroDigits}%
                          </span>
                        </React.Fragment>
                      )
                    ) : holder.percentage2 > 0.001 ? (
                      `${formatNumber(holder.percentage2, true)}%`
                    ) : (
                      <React.Fragment>
                        <span>0.0</span>
                        <SmallText
                          size={textSizes.fontSizes.MOBILE_LITTLE_NUMBER}
                        >
                          {getSmallNumber(holder.percentage2).zeroCount}
                        </SmallText>
                        <span>
                          {getSmallNumber(holder.percentage2).nonZeroDigits}%
                        </span>
                      </React.Fragment>
                    )}
                  </Paragraph>
                </td>
              </tr>
            ))}
        </tbody>
      </StyledCommonHoldersTable>
      <FullRow justify="center" gap="1rem">
        {/* First Page Button */}
        <PaginationButton onClick={() => setPage(1)} disabled={page === 1}>
          1
        </PaginationButton>

        {/* Left Ellipsis */}
        {page > 3 && <Paragraph disabled>...</Paragraph>}

        {/* Dynamic Middle Page PaginationButtons */}
        {Array.from({ length: isDesktop ? 5 : 3 }, (_, i) => {
          const startPage = Math.max(2, Math.min(page - 2, totalPages - 4));
          const middlePage = startPage + i;

          // Do not show the first or last pages here
          if (middlePage >= totalPages || middlePage === 1) return null;

          return (
            <PaginationButton
              key={middlePage}
              onClick={() => setPage(middlePage)}
              disabled={page === middlePage}
            >
              {middlePage}
            </PaginationButton>
          );
        })}

        {/* Right Ellipsis */}
        {page < totalPages - 2 && <Paragraph disabled>...</Paragraph>}

        {/* Last Page PaginationButton */}
        <PaginationButton
          onClick={() => setPage(totalPages)}
          disabled={page === totalPages}
        >
          {totalPages}
        </PaginationButton>
      </FullRow>
    </Column>
  );
}

const StyledCommonHoldersTable = styled.table`
  width: 100%;
  background-color: ${theme.colors.LEVEL};
  border-collapse: collapse;
  border-radius: ${borderRads.SMALL};

  //if smaller than 70rem, no border radius
  @media (max-width: 70rem) {
    border-radius: 0;

    th {
      &:first-child {
        border-top-left-radius: 0 !important;
      }
      &:last-child {
        border-top-right-radius: 0 !important;
      }
    }

    tr > *:nth-child(2) {
      max-width: 8rem !important;
      width: 8rem !important;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    td,
    th {
      ${Paragraph} {
        font-size: 0.85rem !important;
      }
      padding: 0.5rem !important;
    }

    tr > *:nth-child(3),
    tr > *:nth-child(4) {
      width: 8rem !important;
      max-width: 8rem !important;
    }
  }

  //make the table striped
  tbody tr:nth-child(even) {
    background-color: rgba(255, 255, 255, 0.02);
  }

  tr > * {
    text-align: center;
  }

  tr > *:nth-child(1) {
    width: 1rem;
    padding-right: 0;
  }

  tr > *:nth-child(2) {
    width: 50rem;
  }

  tr > *:nth-child(1),
  tr > *:nth-child(2) {
    text-align: left;
  }

  td {
    ${Paragraph} {
      font-size: 0.9rem;
    }
    padding: 0.75rem 1rem;
  }

  tr > *:nth-child(3),
  tr > *:nth-child(4) {
    padding: 0.75rem 0.5rem;
    width: 20rem;
  }

  th {
    padding: 0.75rem 1rem;
    background-color: ${theme.colors.LEVEL};
    font-weight: ${textSizes.fontWeights.MEDIUM};

    &:first-child {
      border-top-left-radius: ${borderRads.SMALL};
    }
    &:last-child {
      border-top-right-radius: ${borderRads.SMALL};
    }
  }
`;

const PaginationButton = styled.button`
  padding: 0.5rem 1rem;
  border-radius: ${borderRads.SMALL};
  background-color: ${({ disabled }) =>
    disabled ? theme.colors.LEVEL : "transparent"};

  border: ${borderWidths.SMALL} solid
    ${({ disabled }) => (disabled ? theme.colors.LEVEL2 : theme.colors.LEVEL2)};
`;
