import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import { BiCopy } from "react-icons/bi";
import { CopyMessage, TransparentButton } from "../buttons/buttons.styled";
import { Icon } from "../icon.styled";
import { textSizes } from "../sizes";
import { StyledCopyButton } from "./copyButton.styled";

export default function CopyButton({ text, isRight, children }) {
  const message = "Copied";

  const [isOn, setOn] = useState(false);

  function copy() {
    if (!isOn) {
      setOn(true);
      setInterval(() => {
        setOn(false);
      }, 5000);
    }
  }

  return (
    <CopyToClipboard text={text}>
      <StyledCopyButton>
        <TransparentButton onClick={() => copy()}>
          {children || (
            <Icon size={textSizes.fontSizes.MEDIUM}>
              <BiCopy />
            </Icon>
          )}
        </TransparentButton>

        <CopyMessage isRight={isRight} isOn={isOn}>
          {message}
        </CopyMessage>
      </StyledCopyButton>
    </CopyToClipboard>
  );
}
