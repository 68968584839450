import { Icon } from "../icon.styled";
import { BiSolidShareAlt } from "react-icons/bi";
import { TransparentButton } from "../buttons/buttons.styled";
import React, { useContext, useState } from "react";
import { StyledShareMenu } from "../menus/shareMenu.styled";
import { ClickAwayListener } from "@mui/base";
import {
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { FaTwitter, FaTelegramPlane, FaWhatsapp } from "react-icons/fa";
import { SmallText } from "../texts.styled";
import { Row } from "../containers/flexbox.styled";
import { DedicatedCoinContext } from "../../context";
import CopyButton from "../misc/copyButton";
import { textSizes } from "../sizes";

export default function DedicatedCoinShareButton({ isMobile, size, coinName }) {
  const [isOn, setOn] = useState(false);

  const coinData = useContext(DedicatedCoinContext);

  return (
    <div
      style={
        isMobile
          ? { position: "relative", marginLeft: "auto" }
          : { position: "relative" }
      }
    >
      <TransparentButton onClick={() => setOn(true)}>
        <Icon
          size={size ? size : isMobile && "1.5rem"}
          color={({ theme }) => theme.colors.LIGHT_TEXT}
        >
          <BiSolidShareAlt />
        </Icon>
      </TransparentButton>
      {isOn && (
        <ClickAwayListener onClickAway={() => setOn(false)}>
          <StyledShareMenu>
            <SmallText>{`Share ${coinData.coinName} on`}</SmallText>
            <Row gap="1rem">
              <TwitterShareButton
                style={{ display: "flex", alignItems: "center" }}
                title={`See ${coinData.coinName} on:`}
                url={`comparemarketcap.com/coin/${coinData.id}`}
                hashtags={[coinData.coinName, coinData.symbol]}
              >
                <Icon>
                  <FaTwitter />
                </Icon>
              </TwitterShareButton>
              <WhatsappShareButton
                style={{ display: "flex", alignItems: "center" }}
                title={`See ${coinData.coinName} on:`}
                url={`comparemarketcap.com/coin/${coinData.id}`}
              >
                <Icon>
                  <FaWhatsapp />
                </Icon>
              </WhatsappShareButton>
              <CopyButton text={`comparemarketcap.com/coin/${coinData.id}`} />
            </Row>
          </StyledShareMenu>
        </ClickAwayListener>
      )}
    </div>
  );
}
